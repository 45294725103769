import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./404.scss"

import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="not-found">
      <h1 className="header-1 not-found-header">404</h1>
      <div className="content">Oops! No page exists at this URL.</div>
      <Link to="/">
        <div className="btn">Go Home</div>
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
